import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <header>
        <div className="container">
          <nav>
            <div className="logo">RosPlat</div>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
