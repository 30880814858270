import React, { Component } from "react";

class Instructions extends Component {
  render() {
    return (
      <section className="instructions">
        <div className="container">
          <h2 className="text-center">
            Как происходит прием и <br />
            обработка платежа?
          </h2>

          <div className="instructions-items">
            <div className="item item--gradient">
              <div className="item__title" data-num="01">
                Авторизуйтесь
              </div>

              <p>После авторизации выберите:</p>

              <ul>
                <li>сумму зачисления;</li>
                <li>валюту (RUB, UZS, KZT и др)</li>
                <li>банк-эмитент своей карты</li>
              </ul>
            </div>

            <div className="item item--input">
              <div className="item__title" data-num="02">
                Реквизиты
              </div>
              <p>Получите реквизиты на которые нужно сделать перевод</p>

              <div className="item__input">0123 **** **** ****</div>
              <div className="item__input item__input--gradient">
                0123 **** **** ****
              </div>
            </div>

            <div className="item">
              <div className="item__title" data-num="03">
                Квитанция
              </div>

              <p>Клиент прикладывает квитанцию и подтверждает перевод</p>

              <div className="item-check">
                <div className="item-check__title">Квитанция #0102131</div>
                <div>Перевод на карту 4141 **** **** **** сделан</div>
              </div>
            </div>

            <div className="item">
              <div className="item__title" data-num="04">
                Начисление
              </div>

              <p>После получения средств, система начисляет сумму в USDT.</p>

              <div className="item-check item-check--last">
                <img src="/assets/img/Tether.png" alt="tether" />
                <div>
                  <div className="item-check__sum">+231,3 $</div>
                  <div>Сегодня</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Instructions;
