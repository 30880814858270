import React, { Component } from "react";

class Hero extends Component {
  render() {
    const toSubscribe = () => {
      window.location.href = "#gettingstarted";
    };
    return (
      <section className="hero">
        <div className="container">
          <div className="hero-row">
            <div className="col order-1">
              <h1>Прием платежей в системе RosPlat</h1>
            </div>

            <div className="col order-2 order-md-4 hero-payment">
              <div className="payment">
                <img
                  className="payment-img"
                  src="/assets/img/cube.svg"
                  alt="cube"
                />

                <div className="payment-info">
                  <div className="payment-info-img"></div>
                  <div>
                    <div className="payment-info-amount">-8,352.13 руб</div>
                    <div>it’s payments</div>
                  </div>
                  <div>Вчера</div>
                </div>
                <div className="payment-info payment-info--second">
                  <img src="/assets/img/Tether.png" alt="Tether" />
                  <div>
                    <div className="payment-info-amount">-3,999 руб</div>
                    <div>RosPlat</div>
                  </div>
                  <div>Сегодня</div>
                </div>
              </div>
            </div>

            <div className="col col--btn order-3">
              <button
                className="btn btn--gradient hero-btn"
                onClick={toSubscribe}
              >
                Подать заявку
              </button>
            </div>

            <div className="col col--text order-4 order-md-2">
              <p className="hero-text">
                Прием платежей в системе RosPlat основан на карточных
                р2р-переводах. Для подключения не требуется наличие счета в
                банке, лицензии и раскрытия бенефициаров. Для оплаты на Вашем
                сайте клиент отправляет сумму переводом по номеру карты. После
                подтверждения получения перевода, компания RosPlat перечисляет
                Ваш счет эквивалент этой суммы в USDT.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
