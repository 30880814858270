import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="footer-wrapper">2021-2023 © RosPlat</div>
        </div>
      </footer>
    );
  }
}

export default Footer;
