

import React from 'react'
import universal, { setHasBabelPlugin } from 'react-universal-component'

setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
  ignoreBabelRename: true
}

const t_0 = universal(import('__react_static_root__/node_modules/react-static/lib/browser/components/Default404'), universalOptions)
      t_0.template = '__react_static_root__/node_modules/react-static/lib/browser/components/Default404'
      
const t_1 = universal(import('__react_static_root__/src/App.js'), universalOptions)
      t_1.template = '__react_static_root__/src/App.js'
      
const t_2 = universal(import('__react_static_root__/src/components/About.jsx'), universalOptions)
      t_2.template = '__react_static_root__/src/components/About.jsx'
      
const t_3 = universal(import('__react_static_root__/src/components/Advantages.jsx'), universalOptions)
      t_3.template = '__react_static_root__/src/components/Advantages.jsx'
      
const t_4 = universal(import('__react_static_root__/src/components/Footer.jsx'), universalOptions)
      t_4.template = '__react_static_root__/src/components/Footer.jsx'
      
const t_5 = universal(import('__react_static_root__/src/components/Header.jsx'), universalOptions)
      t_5.template = '__react_static_root__/src/components/Header.jsx'
      
const t_6 = universal(import('__react_static_root__/src/components/Hero.jsx'), universalOptions)
      t_6.template = '__react_static_root__/src/components/Hero.jsx'
      
const t_7 = universal(import('__react_static_root__/src/components/Instructions.jsx'), universalOptions)
      t_7.template = '__react_static_root__/src/components/Instructions.jsx'
      
const t_8 = universal(import('__react_static_root__/src/components/Subscribe.jsx'), universalOptions)
      t_8.template = '__react_static_root__/src/components/Subscribe.jsx'
      
const t_9 = universal(import('__react_static_root__/src/index.js'), universalOptions)
      t_9.template = '__react_static_root__/src/index.js'
      

// Template Map
export default {
  '__react_static_root__/node_modules/react-static/lib/browser/components/Default404': t_0,
'__react_static_root__/src/App.js': t_1,
'__react_static_root__/src/components/About.jsx': t_2,
'__react_static_root__/src/components/Advantages.jsx': t_3,
'__react_static_root__/src/components/Footer.jsx': t_4,
'__react_static_root__/src/components/Header.jsx': t_5,
'__react_static_root__/src/components/Hero.jsx': t_6,
'__react_static_root__/src/components/Instructions.jsx': t_7,
'__react_static_root__/src/components/Subscribe.jsx': t_8,
'__react_static_root__/src/index.js': t_9
}
// Not Found Template
export const notFoundTemplate = "__react_static_root__/node_modules/react-static/lib/browser/components/Default404"

