import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <section className="about">
        <div className="container">
          <h2 className="text-center">
            Мерчант - контролирует транзакции <br />с помощью личного кабинета
          </h2>
          <div className="about-row">
            <img src="/assets/img/cube.svg" alt="cube" className="about-cube" />
            <img
              src="/assets/img/about-img.png"
              alt="about"
              className="about-img"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default About;
